<template>
  <div id="v-widget">
      <v-card>
          <v-toolbar :color="toolbarColor" :dark="fullscreen || dark" :flat="!fullscreen" :dense="!fullscreen" v-if="enableHeader && !simpleToolbar">
              <slot name="widget-header-previous"></slot>
              <v-icon v-if="icon && !fullscreen" left>{{icon}}</v-icon>
              <v-toolbar-title>
                  <h2 v-if="fullscreen">{{title}}</h2>
                  <h4 v-else>{{title}}</h4>
              </v-toolbar-title>
              <v-badge right style="margin-left:20px;" v-if="badge >= 0">
                  <template v-slot:badge>
                      <span>{{badge}}</span>
                  </template>
              </v-badge>
              <slot name="widget-header-before"></slot>
              <v-spacer></v-spacer>
              <slot name="widget-header-after"></slot> 
              <v-toolbar-items>
                  <slot name="widget-header-action"></slot>
              </v-toolbar-items>
          </v-toolbar>
          <v-system-bar status :color="toolbarColor" :dark="dark" v-if="enableHeader && simpleToolbar">
              <v-icon v-if="icon" left>{{icon}}</v-icon>
              <h5 v-html="title"></h5>
              <slot name="widget-header-before"></slot>
              <v-spacer></v-spacer>
              <slot name="widget-header-action"></slot>
          </v-system-bar>
          <v-divider v-if="enableHeader"></v-divider>
          <v-card-text :class="contentBg">
              <slot name="widget-content"></slot>
          </v-card-text>
          <slot name="widget-actions"></slot>
      </v-card>
  </div>
</template>

<script>
export default {
  name: 'v-widget',
  props: {
    title: {
      type: String,
    },
    badge: {
        type: Number,
        default: -1
    },
    enableHeader: {
      type: Boolean,
      default: true
    },
    contentBg: {
      type: String,
      default: 'white'
    },
    simpleToolbar: {
      type: Boolean,
      default: false
    },
    toolbarColor: {
        type: String,
        default: '#f8f8f9'
    },
    fullscreen: {
        type: Boolean,
        default: false
      },
    icon: {
        type: String,
        default: ''
    },
  },

  data () {
    return {

    };
  },
  computed: {
      dark: {
          get() {
              if (this.toolbarColor == 'primary' || this.toolbarColor == 'secondary' || this.toolbarColor == 'error' || this.toolbarColor == 'success' || this.toolbarColor == 'info')
                  return true;
              else
                  return false;
          },
      }
  },  
};
</script>
