import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Conversation from '../../entities/preferences/conversation'
import Ajax from '../../../lib/ajax' 
import Util from '../../../lib/util'
import PageResult from '@/store/entities/page-result';

interface ConversationState extends ListState<Conversation>{
    editModel: Conversation;
    entity: string;
    entity_id: number;
    typingSend: boolean;
    messages: Array<any>;
    showTypingIndicator: string;
    newMessagesCount: number;
}
class ConversationModule extends ListModule<ConversationState,any,Conversation>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Conversation>(),
        loading: false,
        editModel: new Conversation(),
        activeList: new Array<Conversation>(),
        messages: new Array<any>(),
        showTypingIndicator: '',
        newMessagesCount:0,
        typingSend: false,
        entity: null,
        entity_id: null,
        path: 'conversations'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions   
        async getAll(context: ActionContext<ConversationState, any>, payload: any) {
            context.state.loading = true;
            payload.data.entity = context.state.entity;
            payload.data.entity_id = context.state.entity_id;
            let reponse = await Ajax.get('/api/' + (context.state as any).path, { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            if (typeof reponse.data.result == 'object' && !Array.isArray(reponse.data.result)) {
                let page = reponse.data.result as PageResult<Conversation>;
                context.state.totalCount = page.total;
                context.state.list = page.data;
            } else {
                context.state.totalCount = reponse.data.result.length;
                context.state.list = reponse.data.result;
            }
        },
        async create(context: ActionContext<ConversationState, any>, payload: any) {
            context.state.loading = true;
            payload.data.entity = context.state.entity;
            payload.data.entity_id = context.state.entity_id;
            let response = await Ajax.post('/api/' + (context.state as any).path, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.list.push(response.data.result);
            context.state.messages = Util.setMessages(response.data.result.messages);
        },
        async update(context: ActionContext<ConversationState, any>, payload: any) {
            context.state.loading = true;
            payload.data.entity = context.state.entity;
            payload.data.entity_id = context.state.entity_id;
            let response = await Ajax.put('/api/' + (context.state as any).path + '/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.typingSend = false;
            let conver = context. state.list.filter(item => {
                return item.id == response.data.result.conversation_id;
            });
            if (conver.length > 0) {
                conver[0].messages.push(response.data.result);
                context.state.messages = Util.setMessages(conver[0].messages);
            }
        },
        async updateTyping(context: ActionContext<ConversationState, any>, payload: any) {
            if (context.state.loading == true) return;
            if (context.state.typingSend == true) return;
            context.state.loading = true;
            context.state.typingSend = true;
            payload.data.entity = context.state.entity;
            payload.data.entity_id = context.state.entity_id;
            let response = await Ajax.post('/api/conversations/update-typing', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: ConversationState, model: Conversation) {
            state.editModel = Object.assign({});
            state.editModel.entity = state.entity;
            state.editModel.entity_id = state.entity_id;
        },
        setEntity(state: ConversationState, data: any) {
            state.entity = data.entity;
            state.entity_id = data.entity_id;
        },
        setMessages(state: ConversationState, id: number) {
            state.messages = [];
            let conver = state.list.filter(item => {
                return item.filter_id == id;
            });
            if (conver.length > 0) {
                state.messages = Util.setMessages(conver[0].messages);
            }
        },
        addMessage(state: ConversationState, data: any) {
            let conver = state.list.filter(item => {
                return item.id == data.conversation_id;
            });

            if (conver.length > 0) {
                conver[0].messages.push(data);
                state.messages = Util.setMessages(conver[0].messages);
            }
            state.showTypingIndicator = '';
            state.newMessagesCount++;
        },
        setTypingUser(state: ConversationState, code: string) {
            state.showTypingIndicator = code;
        },
        resetMessagesCount(state: ConversationState) {
            state.newMessagesCount = 0;
        },
    });
}
const workforceobservationModule=new ConversationModule();
export default workforceobservationModule;
