import Entity from '../entity'

export default class Address extends Entity<number>{
    id_address: number;
    phone: string;
    phone_mobile: string;
    alias: string;
    alias2: string;
    address1: string;
    address2: string;
    id_customer: number;
    other: string;
    lat: string;
    lng: string;
    ubigeo: string;
    main: number;
    distance: number;
    duration: number;
}


