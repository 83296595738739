import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Address from '../../entities/account/address'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface AddressState extends ListState<Address>{
    editModel:Address;
}
class AddressModule extends ListModule<AddressState,any,Address>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Address>(),
        loading:false,
        editModel: new Address(),
        path: 'address'          
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async create(context: ActionContext<AddressState, any>, payload: any) {
            context.state.loading = true;
            let data = payload.data;

            let reponse = await Ajax.post('?controller=address&ajax=1&action=createAddress', data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });

            context.state.list = reponse.data.list;
            context.state.loading = false;
            return reponse.data;
        },
        async delete(context: ActionContext<AddressState, any>, payload: any) {
            context.state.loading = true;

            let reponse = await Ajax.post('?controller=address&ajax=1&action=deleteAddress', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });

            context.state.list = reponse.data.list;
            context.state.loading = false;
            return reponse.data;
        },
        async setMain(context: ActionContext<AddressState, any>, payload: any) {
            //context.state.loading = true;
            let id = payload.data.id_address;
            let reponse = await Ajax.post('?controller=address&ajax=1&action=setMainAddress', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });

            context.state.list.forEach((item, index) => {
                item.main = 0;
                if (id == item.id_address) {
                    item.main = 1;
                }
            });

            //context.state.loading = false;
            return reponse.data;
        },
        async getAll(context: ActionContext<AddressState, any>, payload: any) {
            context.state.loading = true;

            let reponse = await Ajax.get('?controller=address&ajax=1&action=getAddresses', {}).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });

            context.state.list = reponse.data;
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: AddressState, model: Address) {
            state.editModel = Object.assign({});
            state.editModel.address1 = '';
        },
    });
}
const addressModule=new AddressModule();
export default addressModule;