import Vue from 'vue';
import VueRouter from 'vue-router';
import {routers} from './router';
//import iView from 'iview';
import Util from '../lib/util';
import Cookies from 'js-cookie'
import store from '../store/index';
import { appRouters, otherRouters } from './router'

Vue.use(VueRouter);

const RouterConfig = {
    // mode: 'history',
    routes: routers
};

export const router = new VueRouter(RouterConfig);

router.beforeEach((to, from, next) => {
    //(store.state as any).app.loading = true; 
    Util.title(to.meta.title); 

    const curRouterObj = Util.getRouterObjByName([otherRouters, ...appRouters], to.name);
    if (curRouterObj && curRouterObj.permission) {
        if (window.abp.auth.hasPermission(curRouterObj.permission)) {
            Util.toDefaultPage([otherRouters, ...appRouters], to.name, router, next);
        } else {
            Util.title('');
            next({
                name: 'home'
            });
        }
    //} else if (curRouterObj == null && to.name == null && Util.isCmsLink(to.path)) {
    //    let cms = Util.getCmsLink(to.path);
    //    next({
    //        name: 'page',
    //        params: { cmd: cms }
    //    });
    } else {
        Util.toDefaultPage([...routers], to.name, router, next);
    }
    
});
router.afterEach((to) => {
    Util.openNewPage(router.app, to.name, to.params, to.query);
    //(store.state as any).app.loading = false;
    window.scrollTo(0, 0);
});
