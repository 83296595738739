import AppConst from './appconst'
import Vue from 'vue';
import Util from './util'
import { register } from 'register-service-worker';
import Pusher from 'pusher-js';
import * as PusherTypes from 'pusher-js';

class PushNotification {
    isPushEnabled: boolean = false;
    pushButtonDisabled: boolean = true;
    swRegistration: any = null;
    conf: any = null;
    vm: any = null;

    init(vm: Vue) {
        this.vm = vm;

        this.conf = {
            key: Util.abp.setting.get('PUSHER_APP_KEY'),
            cluster: Util.abp.setting.get('PUSHER_APP_CLUSTER'),
        };
        this.initPusher();
        this.registerServiceWorker();
    };

    initPusher() {
        Pusher.logToConsole = true;

        this.vm.$store.commit('notification/initNotify');

        var pusher = new Pusher(this.conf.key, {
            cluster: this.conf.cluster
        //    authEndpoint: AppConst.remoteServiceBaseUrl + '?controller=pusher&action=auth',
        //    auth: {
        //        headers: {
        //            'Authorization': "Bearer " + window.abp.auth.getToken(),
        //            'X-Requested-With': 'XMLHttpRequest'
        //        }
        //    }
        });

        //App\Events\EntranceStatus
        var appname = AppConst.localization.defaultLocalizationSourceName.toLocaleLowerCase();
        var channel = pusher.subscribe(appname + '.customer.' + Util.abp.session.userId); //private-user.1
        let $this = this;
        channel.bind('notification-' + appname +'.customer-message', function (data) {
            $this.vm.$store.commit('notification/addNotify', data);
            $this.showNotification(data);
            if (data != undefined && data.data != undefined && data.data.reload != undefined && data.data.reload) {
                location.reload();
            }
            //app.messages.push(JSON.stringify(data));
        });  
    }

    registerServiceWorker() {
        let $this = this;

        //console.log(`${process.env.BASE_URL}service-worker.js`);
        if (process.env.NODE_ENV === 'production') {
            register(`${process.env.BASE_URL}service-worker.js`, {
                ready() {
                    console.log('App is being served from cache by a service worker.\n' + 'For more details, visit https://goo.gl/AFskqB');
                    $this.initialiseServiceWorker();
                },
                error(error) {
                    console.error('Error during service worker registration:', error)
                    console.log('Error during service worker registration:' + error)
                }
            });
        }
    }

    initialiseServiceWorker() {
        if (!('showNotification' in ServiceWorkerRegistration.prototype)) {
            console.log('Notifications aren\'t supported.')
            return
        }
        if (Notification.permission === 'denied') {
            console.log('The user has blocked notifications.')
            return
        }
        if (!('PushManager' in window)) {
            console.log('Push messaging isn\'t supported.')
            return
        }
        navigator.serviceWorker.ready.then(registration => {
            registration.pushManager.getSubscription()
                .then(subscription => {
                    console.log(subscription);
                    this.pushButtonDisabled = false
                    if (!subscription) {
                        if (!this.isPushEnabled)
                            this.subscribe();
                        return;
                    }
                    this.updateSubscription(subscription)
                    this.isPushEnabled = true
                })
                .catch(e => {
                    console.log('Error during getSubscription()', e)
                })
        })
    };

    /**
     * Subscribe for push notifications.
     */
    subscribe() {
        navigator.serviceWorker.ready.then(registration => {
            const options = { userVisibleOnly: true }
            const vapidPublicKey = this.conf.vapidPublicKey
            if (vapidPublicKey) {
                options['applicationServerKey'] = this.urlBase64ToUint8Array(vapidPublicKey)
            }
            registration.pushManager.subscribe(options)
                .then(subscription => {
                    this.isPushEnabled = true
                    this.pushButtonDisabled = false
                    this.updateSubscription(subscription)
                })
                .catch(e => {
                    if (Notification.permission === 'denied') {
                        console.log('Permission for Notifications was denied')
                        this.pushButtonDisabled = true
                    } else {
                        console.log('Unable to subscribe to push.', e)
                        this.pushButtonDisabled = false
                    }
                })
        })
    };

    /**
     * Unsubscribe from push notifications.
     */
    unsubscribe() {
        navigator.serviceWorker.ready.then(registration => {
            registration.pushManager.getSubscription().then(subscription => {
                if (!subscription) {
                    this.isPushEnabled = false
                    this.pushButtonDisabled = false
                    return
                }
                subscription.unsubscribe().then(() => {
                    this.deleteSubscription(subscription)
                    this.isPushEnabled = false
                    this.pushButtonDisabled = false
                }).catch(e => {
                    console.log('Unsubscription error: ', e)
                    this.pushButtonDisabled = false
                })
            }).catch(e => {
                console.log('Error thrown while unsubscribing.', e)
            })
        })
    };

    /**
     * Send a request to the server to update user's subscription.
     *
     * @param {PushSubscription} subscription
     */
    updateSubscription(subscription) {
        const key = subscription.getKey('p256dh')
        const token = subscription.getKey('auth')
        const contentEncoding = (PushManager.supportedContentEncodings || ['aesgcm'])[0]
        const data = {
            //endpoint: subscription.endpoint,
            //publicKey: key ? btoa(String.fromCharCode.apply(null, new Uint8Array(key))) : null,
            //authToken: token ? btoa(String.fromCharCode.apply(null, new Uint8Array(token))) : null,
            //contentEncoding
        }

        this.vm.$store.dispatch({
            type: 'user/subscription',
            data: data
        });
    };

    /**
     * Send a requst to the server to delete user's subscription.
     *
     * @param {PushSubscription} subscription
     */
    deleteSubscription(subscription) {
        //this.loading = true
        //axios.post('/subscriptions/delete', { endpoint: subscription.endpoint })
        //    .then(() => { this.loading = false })
    };

    /**
     * Send a request to the server for a push notification.
     */
    //sendNotification() {
    //    //this.loading = true
    //    //axios.post('/notifications')
    //    //    .catch(error => console.log(error))
    //    //    .then(() => { this.loading = false })
    //};

    /**
     *
     * @param  {String} base64String
     * @return {Uint8Array}
     */
    urlBase64ToUint8Array(base64String) {
        const padding = '='.repeat((4 - base64String.length % 4) % 4)
        const base64 = (base64String + padding)
            .replace(/\-/g, '+')
            .replace(/_/g, '/')
        const rawData = window.atob(base64)
        const outputArray = new Uint8Array(rawData.length)
        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i)
        }
        return outputArray
    }

    sendNotification(data) {
        if (Notification.permission === 'granted') {
            navigator.serviceWorker.getRegistration()
                .then(function (reg) {
                    if (reg == undefined) {
                        console.log("only works online")
                        return
                    }
                    var options = {
                        body: data.description,
                        icon: data.icon,
                        badge: data.icon,
                        vibrate: [100, 50, 100],
                        data: {
                            dateOfArrival: Date.now(),
                            primaryKey: data.id
                        },
                        actions: [
                            {
                                action: 'view_app',
                                title: 'Ir a la tienda',
                                icon: './static/img/checkmark.png'
                            },
                            {
                                action: 'close',
                                title: 'Cerrar',
                                icon: './static/img/xmark.png'
                            }
                        ]
                    }
                    reg.showNotification(data.title, options)
                })
        }
    }

    showNotification(data) {
        if (Notification != undefined && Notification.requestPermission() != undefined) {
            Notification.requestPermission().then(function (a) {
                //console.info("Permission html5 notifications: " + a);
                if (a === "granted") {

                }
            })
        }

        if (Notification.permission === 'granted') {
            navigator.serviceWorker.ready.then(function (registration) {
                var options = {
                    body: data.description,
                    icon: data.icon,
                    badge: data.icon,
                    vibrate: [100, 50, 100],
                    data: {
                        dateOfArrival: Date.now(),
                        primaryKey: data.id
                    },
                    actions: [
                        //{
                        //    action: 'view_app',
                        //    title: 'Ir a la tienda',
                        //    icon: './static/img/checkmark.png'
                        //},
                        {
                            action: 'close',
                            title: 'Cerrar',
                            icon: './static/img/xmark.png'
                        }
                    ]
                }

                registration.showNotification(data.title, options);
            });
        }
    }

    sendTestNoty() {
        //Notification.requestPermission(function (status) {
        //    console.log('Notification permission status:', status);
        //});
        //if (Notification.permission === 'granted') {
        //    navigator.serviceWorker.getRegistration()
        //        .then(function (reg) {
        //            if (reg == undefined) {
        //                console.log("only works online")
        //                return
        //            }
        //            var options = {
        //                body: 'First notification!',
        //                icon: './static/img/notification-flat.png',
        //                vibrate: [100, 50, 100],
        //                data: {
        //                    dateOfArrival: Date.now(),
        //                    primaryKey: 1
        //                },
        //                actions: [
        //                    {
        //                        action: 'explore',
        //                        title: 'Go to the site',
        //                        icon: './static/img/checkmark.png'
        //                    },
        //                    {
        //                        action: 'close',
        //                        title: 'Close the notification',
        //                        icon: './static/img/xmark.png'
        //                    }
        //                ]
        //            }
        //            reg.showNotification('Your Message Here!', options)
        //        })
        //}   
    }
}
export default new PushNotification();