import Entity from '../entity'

export default class Conversation extends Entity<number>{
    entity: string;
    entity_id: number;
    filter_id: number;
    user_id: number;
    dealer_id: number;
    dealer_user_id: number;
    user_typing: boolean;
    dealer_typing: boolean;

    messages: Array<any>;
}


