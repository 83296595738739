import axios from 'axios'
import appconst from './appconst'
import Util from './util'
import Vue from 'vue'
const ajax = axios.create({
    baseURL: appconst.remoteServiceBaseUrl,
    timeout: appconst.setting.timeout,
});
ajax.interceptors.request.use(function (config) {
    if(!!window.abp.auth.getToken()){
        config.headers.common["Authorization"]="Bearer "+window.abp.auth.getToken();
    }
    config.headers.common["X-Requested-With"] = 'XMLHttpRequest';
    //config.headers.common[".AspNetCore.Culture"]=window.abp.utils.getCookieValue("Abp.Localization.CultureName");
    return config;
  }, function (error) {
    
    return Promise.reject(error);
});
let vm=new Vue({});
ajax.interceptors.response.use((respon) => {
    if (respon.data && respon.data.success)
        return respon
    else if (respon.data && (respon.data.type == 'application/pdf' ||
        respon.data.type == 'text/xml' ||
        respon.data.type == 'application/zip' ||
        respon.data.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'))
        return respon
    else {
        vm.$toast.error(respon.data.error);
        return Promise.reject(respon.data.error);
    }
}, (error) => {
    let errorMsg = '';
    if (!!error.response && !!error.response.data.error && !!error.response.data.error.message && error.response.data.error.details) {
        errorMsg = error.response.data.error.message + '  ' + error.response.data.error.details;
    } else if (!!error.response && !!error.response.data.error && !!error.response.data.error.message) {
        errorMsg = error.response.data.error.message;
    } else if (!!error.response && !!error.response.data.errors) {
        let obj = error.response.data.errors;
        for (const prop in obj) {
            obj[prop].forEach(er => {
                errorMsg += er + '<br/>';
            });
        }
    } else if (!!error.response && !!error.response.data.message) {
        errorMsg = error.response.data.message;
    } else if (!error.response) {
        errorMsg = window.abp.localization.localize('UnknownError');
    } else {
        errorMsg = 'Ocurrio un error al procesar la solicitud';
    }

    if (errorMsg != '') {
        //vm.$toast.error(error.response.data.error.message + '  ' + error.response.data.error.details);
        Util.abp.notify.error(vm, errorMsg);
    }

    //setTimeout(()=>{
    //   vm.$Message.destroy();
    //},1000);
    return Promise.reject(error);
    //return false;
})
export default ajax;