import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import City from '../../entities/preferences/city'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface CityState extends ListState<City>{
    editModel:City;
    activeList: Array<City>;
}
class CityModule extends ListModule<CityState,any,City>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<City>(),
        loading:false,
        editModel: new City(),
        activeList: new Array<City>(),
        path: 'customer/cities'  
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations        
    });
}
const cityModule=new CityModule();
export default cityModule;